<template>
  <div class="vendor-general">
    <v-card outlined>
      <div class="pl-lg-9 px-sm-7 py-sm-8 py-3 px-3 item-content">
        <div class="text-h6 font-weight-bold">General details</div>
        <div class="text--text">Please, provide information about Vendor in both Arabic and English languages</div>
        <div class="d-flex align-center mt-4">
          <div class="font-weight-bold pe-6">Status</div>
          <v-btn-toggle class="group-btn me-10" v-model="data.status" @change="updateStatus">
            <v-btn class="text-body-2" value="listed" outlined height="36">Listed</v-btn>
            <v-btn class="text-body-2" value="unlisted" outlined height="36">Unlisted</v-btn>
          </v-btn-toggle>
        </div>
        <div class="font-weight-bold mb-1 mt-4">Order prefix</div>
        <v-text-field
          class="field46 prefix-field"
          v-model="data.prefix"
          placeholder="Prefix"
          :error-messages="orderErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="font-weight-bold mb-2 mt-n2">Title</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data.title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="d-flex flex-md-row flex-column mt-n2 multi-direct">
          <v-textarea
            class="width100 me-md-3"
            v-model="data.description_en"
            placeholder="Description"
            :error-messages="desEnErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
          <v-textarea
            class="width100 mt-md-0 mt-n2 ms-md-3"
            v-model="data.description_ar"
            placeholder="وصف كامل"
            :error-messages="desArErrors"
            auto-grow
            rows="4"
            row-height="30"
            outlined
            dense
            required
            color="primary"
          ></v-textarea>
        </div>
        <div class="font-weight-bold mb-3 mt-n2">Email</div>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="font-weight-bold mb-3 mt-n2">Upload logo</div>
        <v-card @click="onFileChange($event)" flat class="d-flex align-center justify-center card-img" width="200" height="200">
          <div class="primary--text text-center" v-if="!data.picture">
            <v-img max-width="44" class="mx-auto primary-filter" src="@/assets/icon/upload.svg"></v-img>
            <div class="font-weight-bold">Upload logo</div>
            <div class="f13">200x200</div>
          </div>
          <img width="198" height="198" v-else :src="data.picture" />
          <input type="file" accept="image/*" @change="onFileChange($event)" />
        </v-card>
      </div>
      <v-divider></v-divider>
      <div class="px-sm-10 py-3 px-3 item-content text-end">
        <v-btn @click="updateItem" depressed class="primary" large width="136">Save</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch('getVendorItem', this.profile.product_vendor.id);
    }, 300);
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor info saved successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Vendor info updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async updateStatus() {
      const data = new Object();
      data.status = this.data.status;
      await this.$store.dispatch('statusVendorItem', { id: this.profile.product_vendor.id, data: data }).then(() => {
        this.notifi('update');
      });
    },
    async onFileChange(e) {
      this.file = e.target.files;
      if (!this.file.length) {
        return;
      } else {
        this.error = [];
        const formData = new FormData();
        formData.append('picture', this.file[0]);
        await this.$store
          .dispatch('setPhotoVendor', { id: this.profile.product_vendor.id, data: formData })
          .then(() => {
            this.notifi('updatePicture');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async updateItem() {
      this.error = [];
      const data = new Object();
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.order_prefix = this.data.prefix;
      data.email = this.data.email;
      await this.$store
        .dispatch('updateVendorItem', { id: this.profile.product_vendor.id, data: data })
        .then(() => {
          this.notifi('saved');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.vendor;
    },
    profile() {
      return this.$store.getters.profile;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please enter title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    desEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    desArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    pictureErrors() {
      const errors = [];
      this.error.find((item) => item == 'picture__required') && errors.push('Please select picture');
      this.error.find((item) => item == 'picture__invalid') && errors.push('Selected picture is not valid');
      return errors;
    },
    orderErrors() {
      const errors = [];
      this.error.find((item) => item == 'order_prefix__required') && errors.push('Please enter order prefix');
      this.error.find((item) => item == 'order_prefix__invalid') && errors.push('Provided order prefix is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setVendorItem');
  },
};
</script>

<style lang="scss">
.vendor-general {
  .item-content {
    max-width: 1150px;
  }
  .prefix-field {
    .v-input__slot {
      max-width: 116px !important;
    }
  }
  .v-input {
    max-width: 371px;
  }
  .card-img {
    background: var(--v-primary-lighten3);
    border: 1px dashed var(--v-primary-base);
    img {
      object-fit: contain;
    }
  }
  @media screen and (max-width: 700px) {
    .v-input {
      max-width: initial !important;
    }
    .group-btn {
      display: grid;
      button {
        border-width: thin !important;
        border-radius: 4px !important;
      }
    }
  }
}
</style>
